import { captureException } from '@sentry/browser'
// import config from '../config/config.json'

export const logError = (error: Error) => {
  //   if (config.env === 'test') {
  //     console.log(error)
  //   }

  captureException(error)
}
