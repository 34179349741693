import React from 'react'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'
// import {tiffanyBlueColor, textHeaderColor} from "../../styles";

/**
 * @param {id} param unique id for the tooltip. this is handy if there are more than 1 tooltips on the page
 * @param {place} param in which direction the tooltip should render
 *
 */
export function Tooltip({ id, place }) {
  return (
    <StyledTooltip
      id={id}
      place={place}
      className={`tooltip-${place}`}
      type="info"
      effect="solid"
      multiline
    />
  )
}

export const StyledTooltip = styled(ReactTooltip)`
  color: white;
  background-color: #1dc7b5 !important;

  &.tooltip-right {
    :after {
      border-right: 8px solid #1dc7b5 !important;
    }
  }

  &.tooltip-left {
    :after {
      border-left: 8px solid red !important;
    }
  }
`
