import React from 'react'
import styled from 'styled-components'

interface Props {
  children: any
}
export default function SubHeaderText({ children }: Props) {
  return <StyledSubHeaderText>{children}</StyledSubHeaderText>
}

const StyledSubHeaderText = styled.p`
  margin-top: 6px;
  color: ${({ theme }) => theme.colors.whiteText};
  font-family: ${({ theme }) => theme.font};
  font-size: ${({ theme }) => theme.fontSizes.subHeader};
`
