import React from 'react'
import { FaInfoCircle } from 'react-icons/fa'
// import { tiffanyBlueColor } from '../../styles'
import { theme } from '../../styles/Theme'
/**
 *
 * @param {text} param the text to be displayed inside the tooltip
 * @param {dataFor} param the id of the tooltip.
 */
export default function TooltipInfo({
  text,
  dataFor = '',
  containerStyle = {},
}) {
  return (
    <FaInfoCircle
      data-tip={text}
      data-for={dataFor}
      size={20}
      color={theme.colors.primaryGreenColor}
      style={containerStyle}
    />
  )
}
