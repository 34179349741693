import { useEffect, useState } from 'react'
import './App.css'
import Theme from './styles/Theme'
import GlobalStyle from './styles/globalStyle'
import Layout from './components/layout/Layout'
import HeaderText from './components/common/HeaderText'
import SubHeaderText from './components/common/SubHeaderText'
import MasterForm from './components/masterform/MasterForm'
import getLeadIdFromParams from './util/getLeadIdFromParams'
import TeamTelefoonLogoSpinner from './components/common/TeamTelefoonSpinner'
import Logo from './components/common/Logo'
import { FirebaseLeadsRepository } from './FirebaseLeadsRepository'
import i18n from './i18n/i18n'
import { logError } from './logging/SentryLogger'

const App = () => {
  const [lead, setLead] = useState({
    name: '',
    organisationName: '',
    status: '',
    email: '',
    id: '',
    isLoading: true,
  })

  useEffect(() => {
    const leadId = getLeadIdFromParams()
    if (!leadId) {
      return setLead((prevLead) => ({ ...prevLead, isLoading: false }))
    }

    const leadsRepository = new FirebaseLeadsRepository()

    ;(async () => {
      try {
        const retrievedLead = await leadsRepository.getLead(leadId)
        if (!retrievedLead) {
          return setLead((prevLead) => ({ ...prevLead, isLoading: false }))
        }

        return setLead({ ...retrievedLead, isLoading: false })
      } catch (error) {
        logError(error)
        return setLead((prevLead) => ({ ...prevLead, isLoading: false }))
      }
    })()
  }, [])

  return (
    <Theme>
      <GlobalStyle />
      <Layout>
        <Logo />
        {lead.isLoading ? (
          <TeamTelefoonLogoSpinner />
        ) : lead.id ? (
          <>
            <HeaderText>{i18n.t('team.title')}</HeaderText>
            <MasterForm
              id={lead.id}
              email={lead.email}
              name={lead.name}
              organisationName={lead.organisationName}
              status={lead.status}
            />
          </>
        ) : (
          <div>
            <HeaderText>{i18n.t('lead.requestNotFound')}</HeaderText>
            <SubHeaderText>
              {i18n.t('lead.notFoundDescription')}{' '}
              <a href="https://www.teamtelefoon.nl/aanvraag">
                {i18n.t('lead.notFoundHere')}!
              </a>
            </SubHeaderText>
          </div>
        )}
      </Layout>
    </Theme>
  )
}

export default App
