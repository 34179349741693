import {createGlobalStyle} from "styled-components";

export default createGlobalStyle`

body {
    padding: 0;
    margin: 0;
    font-family: ${({theme}) => theme.font};
    box-sizing: border-box;
    color: ${({theme}) => theme.colors.textColor};
    background-color: ${({theme}) => theme.colors.backgroundColorLight};
  }`;
