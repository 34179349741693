import styled from 'styled-components'

const ErrorText = styled.span`
  padding: 5px;
  font-family: ${({ theme }) => theme.font};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.small};
  color: ${({ theme: { colors } }) => colors.destructiveRedColor};
`

export default ErrorText
