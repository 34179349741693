import withStyles from '@material-ui/core/styles/withStyles'
import Switch from '@material-ui/core/Switch'

export default withStyles({
  switchBase: {
    '&$checked': {
      color: '#1DC7B5',
    },
    '&$checked + $track': {
      backgroundColor: '#1DC7B5',
    },
  },
  checked: {},
  track: {},
})(Switch)
