import React from 'react'
import styled from 'styled-components'

interface Props {
  name: string
  label: string
  type: string
  value?: string
  dark?: boolean
  placeholder?: string
  handleChange: any // ?
  required?: boolean
}
export default function InputFieldWithLabel({
  name,
  type,
  label,
  handleChange,
  value,
  ...otherProps
}: Props) {
  return (
    <StyledInputFieldWithLabel>
      {label ? (
        <StyledLabel {...otherProps} htmlFor={name}>
          {label}
        </StyledLabel>
      ) : null}
      <StyledInput
        name={name}
        id={name}
        type={type}
        onChange={handleChange}
        value={value}
        {...otherProps}
      />
    </StyledInputFieldWithLabel>
  )
}

export const StyledInputFieldWithLabel = styled.div`
  display: flex;
  flex-direction: column;
`
export const StyledLabel = styled.label<{ dark?: boolean }>`
  font-size: 18px;
  margin-bottom: 8px;
  color: ${(props) => (props.dark ? '#747474' : 'white')};
`
export const StyledInput = styled.input<{ dark?: boolean }>`
  border: none;
  margin-bottom: 8px;
  padding: 10px 15px;
  border-radius: 3px;
  background-color: ${(props) => (props.dark ? '#EFEFEF' : 'white')};
  &: focus {
    outline-width: 1px;
    outline-color: ${({ theme }) => theme.colors.primaryGreenColor};
  }
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${({ theme }) => theme.colors.secondaryGreyColorLight};
  }
`
