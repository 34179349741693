import * as yup from 'yup'
import { Member } from '../types'
export const validateTeamMember = async (member: Member) => {
  let schema = yup.object().shape({
    name: yup
      .string()
      .typeError('Naam kan geen getal zijn')
      .required('Teamlid heeft geen naam.')
      .min(2, 'Naam moet uit minstens twee karakters bestaan.'),
    phoneNumber: yup
      .number()
      .typeError('Telefoonnummer moet een getal zijn')
      .required('Geen telefoonnummer ingevuld'),
  })
  return await schema.validate(member)
}

export default validateTeamMember
