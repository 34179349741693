import React from 'react'
import HeaderText from '../../common/HeaderText'
import SubHeaderText from '../../common/SubHeaderText'
import i18n from '../../../i18n/i18n'

const Header = () => {
  return (
    <div>
      <HeaderText>{i18n.t('team.teamMembers')}</HeaderText>
      <SubHeaderText>{i18n.t('team.teamMemberDescription')}</SubHeaderText>
    </div>
  )
}
export default Header
