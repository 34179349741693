import styled from 'styled-components'

export const StyledAddMemberDiv = styled.div`
  display: block;
  border-radius: 5px;
  border-shadow: 0px 0px 5px 0px #000000;
  background-color: white;
  padding: 30px 30px 20px 30px;
`

export const StyledTeamMember = styled.div`
  display: flex;
  border-shadow: 0px 0px 5px 0px #000000;
  border-radius: 5px;
  background-color: white;
  padding: 0px 10%;
  justify-content: space-between;
  margin: 20px 0px;
`
