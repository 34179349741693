import React from 'react'
import i18n from '../../../i18n/i18n'
import { Member } from '../../../types'
import Button from '../../common/Button'
import { StyledTeamMember } from '../styled'

interface Props {
  members: Member[]
  removeTeamMember: (memberId: number) => void
}
const TeamMembersOverview = ({ members, removeTeamMember }: Props) => {
  return (
    <div>
      {members.map((member: Member) => {
        return (
          <StyledTeamMember key={member.id}>
            <div>
              <p>{member.name}</p>
              <p>{member.phoneNumber}</p>
              {member.keyUser ? <p>{i18n.t('team.keyUser')}</p> : null}
            </div>
            <Button onClick={() => removeTeamMember(member.id)}>
              {i18n.t('team.delete')}
            </Button>
          </StyledTeamMember>
        )
      })}
    </div>
  )
}

export default TeamMembersOverview
