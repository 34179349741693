import React, { ReactElement } from 'react'
import styled from 'styled-components'
import logo from '../../../src/assets/TeamTelefoon-logo-white.png'

export default function Logo(): ReactElement {
  return (
    <div>
      <LogoImg src={logo} />
    </div>
  )
}

const LogoImg = styled.img`
  padding: 20px 0px;
  width: 250px;
`
