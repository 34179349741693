import React from 'react'
import styled from 'styled-components'

interface Props {
  children: any
}
export default function HeaderText({ children }: Props) {
  return <StyledHeaderText>{children}</StyledHeaderText>
}

const StyledHeaderText = styled.h1`
  color: ${({ theme }) => theme.colors.whiteText};
  font-family: ${({ theme }) => theme.font};
  font-size: ${({ theme }) => theme.fontSizes.header};
  margin-bottom: 6px;
`
