//import RNLanguages from 'react-native-languages'
import i18n from 'i18n-js'
import moment from 'moment'
import 'moment/locale/nl'

import en from './translations/en.json'
import nl from './translations/nl.json'

i18n.locale = navigator.language
i18n.fallbacks = true
i18n.translations = { en, nl }
moment.locale(i18n.currentLocale())

export default i18n
