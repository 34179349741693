import React, { ReactElement } from 'react'
import styled, { keyframes } from 'styled-components'
import logo from '../../assets/teamfone-logo-white.svg'

interface Props {
  height?: string
}
export default function TeamTelefoonLogoSpinner({
  height,
}: Props): ReactElement {
  return (
    <ImgContainer height={height}>
      <RotatedImg src={logo} />
    </ImgContainer>
  )
}

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const ImgContainer = styled('div')<{ height?: string }>`
  height: ${({ height }) => (height ? height : '100vh')};
  width: 100%;
  display: flex;
  justify-content: center;
`
const RotatedImg = styled.img`
  display: inline-block;
  animation: ${rotate} 3s linear infinite;
  width: 100px;
  padding: 0px 8px;
  font-size: 1.2rem;
`
