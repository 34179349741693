import React from "react";
import {ThemeProvider} from "styled-components";

export const theme = {
    colors: {
        backgroundColorLight: "#1DC7B5",
        backgroundColorMedium: "#E4E4E4",
        primaryGreenColor: "#1DC7B5",
        primaryGreenColorLight: "#4ED4C7",
        primaryOrangeColor: "#EE5F4B",
        destructiveRedColorLight: "#FF6c6c",
        secondaryGreyColor: "#ABABAB",
        secondaryGreyColorLight: "#CDCDCD",
        whiteText: "#fff",
        tiffanyBlueColor: "#1DC8B6",
    },
    font: "'PT Sans', sans-serif",
    fontSizes: {
        normal: "1em",
        small: "0.75em",
        header: "28px",
        subHeader: "18px",
    },
    fontWeights: {
        regular: "normal",
        semiBold: "600",
        bold: "bold",
    },
    padding: {
        quarterSpacing: "2.5px",
        halfSpacing: "5px",
        spacing: "10px",
    },
};

const Theme = ({children}: {children: JSX.Element[]}) => (
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;
