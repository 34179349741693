import React from 'react'
import styled from 'styled-components'

export default function Button({ children, ...otherProps }: any) {
  return (
    <StyledButton type="button" {...otherProps}>
      {children}
    </StyledButton>
  )
}
const StyledButton = styled.button`
  background-color: ${({ theme, disabled }) =>
    disabled
      ? theme.colors.secondaryGreyColor
      : theme.colors.primaryOrangeColor};
  font-size: 16px;
  margin: 10px 0px;
  padding: 8px;
  color: ${({ theme }) => theme.colors.whiteText};
  border: none;
  border-radius: 5px;
  display: block;
  font-family: ${({ theme }) => theme.font};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  align-self: center;
`
