import { initialTeamState } from './initialTeamState'
import { Team } from './types'
export const reducer = (state: Team, action: any) => {
  switch (action.type) {
    case 'setTeamName':
      return { ...state, ...action.payload }
    case 'addTeamMember':
      return {
        ...state,
        members: state.members.concat(action.payload),
      }
    case 'removeTeamMember':
      return {
        ...state,
        members: state.members.filter(
          (member) => member.id !== action.payload.id
        ),
      }
    case 'resetTeamState':
      return initialTeamState
    default:
      throw new Error('Unknown action')
  }
}
