import React, { useState } from 'react'
import { Member } from '../../../types'
import Button from '../../common/Button'
import InputFieldWithLabel from '../../common/InputField'
import { StyledAddMemberDiv } from '../styled'
import Switch from '../../common/Switch'
import ErrorText from '../../common/ErrorText'
import validateTeamMember from '../../../util/validateTeamMember'
import styled from 'styled-components'
import { Tooltip, TooltipInfoIcon } from '../../tooltip'
import i18n from '../../../i18n/i18n'

interface Props {
  addTeamMember: (member: Member) => void
}
const AddAMemberSection = ({ addTeamMember }: Props) => {
  const [member, setMember] = useState<Member>({
    name: '',
    phoneNumber: '',
    id: 0,
    keyUser: false,
  })
  const [error, setError] = useState('')
  const [isAddingUserToTheTeam, setIsAddingUserToTheTeam] = useState(false)
  const handleAddTeamMember = async () => {
    setIsAddingUserToTheTeam(true)
    try {
      await validateTeamMember(member)
      setError('')
    } catch (error) {
      setIsAddingUserToTheTeam(false)
      return setError(error.errors)
    }

    addTeamMember(member)
    setMember({ name: '', phoneNumber: '', id: 0, keyUser: false })
    setIsAddingUserToTheTeam(false)
  }
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    const { name, value } = event.target
    setMember({ ...member, [name]: value })
  }

  return (
    <StyledAddMemberDiv>
      <InputFieldWithLabel
        dark
        type="text"
        name="name"
        label={i18n.t('team.name')}
        handleChange={handleChange}
        value={member.name}
      />
      <InputFieldWithLabel
        dark
        type="number"
        name="phoneNumber"
        label="06-nummer"
        handleChange={handleChange}
        value={member.phoneNumber}
        placeholder="06 12345678"
      />
      <KeyUserInfoContainer>
        {i18n.t('team.keyUser')}
        <TooltipInfoIcon
          text={i18n.t('team.keyUserDescription')}
          dataFor="keyUser"
          containerStyle={{ marginLeft: '5px' }}
        />
        <Tooltip place="right" id="keyUser" />
      </KeyUserInfoContainer>

      <Switch
        name="coreUser"
        onChange={() => setMember({ ...member, keyUser: !member.keyUser })}
        checked={member.keyUser}
      />
      <Button
        onClick={handleAddTeamMember}
        loading={isAddingUserToTheTeam.toString()}
        disabled={isAddingUserToTheTeam}
      >
        {i18n.t('team.addMember')}
      </Button>
      {error ? <ErrorText>{error}</ErrorText> : null}
    </StyledAddMemberDiv>
  )
}

export default AddAMemberSection

const KeyUserInfoContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 0.5em;
`
