import React from 'react'
import { Member } from '../../../types'
import AddAMemberSection from './AddAMemberSection'
import Header from './Header'
import TeamMembersOverview from './TeamMembersOverview'

interface Props {
  members: Member[]
  addTeamMember: (member: Member) => void
  removeTeamMember: (memberId: number) => void
}
const TeamMembers = ({ members, addTeamMember, removeTeamMember }: Props) => {
  return (
    <div>
      <Header />
      <AddAMemberSection addTeamMember={addTeamMember} />
      <TeamMembersOverview
        members={members}
        removeTeamMember={removeTeamMember}
      />
    </div>
  )
}

export default TeamMembers
