import React from 'react'
import i18n from '../../i18n/i18n'
import FormLabelInput from '../common/InputField'

interface Props {
  handleTeamNameChange: (event: any) => void
  teamName: string
}
const TeamDetails = ({ handleTeamNameChange, teamName }: Props) => {
  return (
    <FormLabelInput
      name="name"
      type="text"
      label={i18n.t('team.teamNameQuestion')}
      value={teamName}
      handleChange={handleTeamNameChange}
      required
    />
  )
}

export default TeamDetails
